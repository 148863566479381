<template>
  <div v-if="myMedia.length > 0">
    <b-row>
      <b-col md="4" sm="6" class="mb-4" v-for="(m, index) in myMedia" :key="m.id">
        <router-link :to="myLinks[index]">
          <b-card no-body class="a py-3 px-4">
            <div class="row" style="height: 78px;">
              <div class="col-2 align-self-center">
                <svg width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 20.2105C14.8457 20.2105 17.1257 17.9537 17.1257 15.1579L17.1429 5.05263C17.1429 2.25684 14.8457 0 12 0C9.15429 0 6.85714 2.25684 6.85714 5.05263V15.1579C6.85714 17.9537 9.15429 20.2105 12 20.2105ZM21.0857 15.1579C21.0857 20.2105 16.7314 23.7474 12 23.7474C7.26857 23.7474 2.91429 20.2105 2.91429 15.1579H0C0 20.9011 4.66286 25.6505 10.2857 26.4758V32H13.7143V26.4758C19.3371 25.6674 24 20.9179 24 15.1579H21.0857Z" fill="#005B70"/>
                </svg>
              </div>
              <div class="col-10 align-self-center">
                <h2 v-if="$root.lang.shortName=='en'" class="mediaTitle" :title="m.titleEn">{{ m.titleEn | removeLat }}</h2>
                <h2 v-else  class="mediaTitle" :title="m.title">{{ m.title | removeLat }}</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-10 offset-2 audioFooter">
                {{ $t('videos.author') }}: {{ m.author | removeLat }}
              </div>
            </div>
          </b-card>
        </router-link>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  name : "MediaAudioGrid",
  components : { BCard },
  data : function() {
    return {
      links: []
    }
  },
  
  props : {
    media : Array
  },

  computed: {
    myMedia : function() {
      return this.media;
    },

    myLinks : function() {
      var linkz = []
      for (var i = 0; i < this.myMedia.length; i++) {
        const m = this.myMedia[i];
        const link  = { name: 'SingleAudio', params : { lang: this.$root.lang.shortName, audioId: m.id } };
        linkz.push(link);
      }
      return linkz;
    }
  }
}
</script>
<style></style>
