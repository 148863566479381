<template>
<div>
  <b-row>
    <b-col md="4" sm="6" class="mb-4" v-for="(m, index) in myMedia" :key="m.id">
      <router-link :to="myLinks[index]">
        <b-card no-body class="m">
          <div v-if="m.mimeType.indexOf('video') >= 0">
            <div class="posterPlaceHolder embed-responsive embed-responsive-16by9">
              <video @mouseover="playWebm" @mouseleave="stopWebm" muted preload="auto" loop :src="'/'+m.gifUrl" class="gridVideo"></video>
              <b-img  class="mediaGridPlayButton" :src="require('@/assets/img/media-grid-play-icon.svg')" />
            </div>
          </div>
          <div v-if="m.mimeType.indexOf('image') >= 0">
            <div class="posterPlaceHolder">
             <b-img :src="m.posterUrl" fluid-grow />
            </div>
          </div>
          <b-card-text class="m" text-tag="div">
            <h2 v-if="$root.lang.shortName=='en'" class="mediaTitle" :title="m.titleEn">{{ m.titleEn | removeLat }}</h2>
            <h2 v-else  class="mediaTitle" :title="m.title">{{ m.title | removeLat }}</h2>
          </b-card-text>
          <b-card-footer class="m">{{ $t('videos.author') }}: {{ m.author | removeLat }}</b-card-footer>
        </b-card>
      </router-link>
    </b-col>
  </b-row>
</div>
</template>

<script>
import { BCard, BCardText, BCardFooter, BImg } from 'bootstrap-vue'
export default {
  name : "MediaGrid",
  components : { BCard, BCardText, BCardFooter, BImg },
  data : function() {
    return {
      posters: [],
      gifs: [],
      links: []
    }
  },

  created() {
    window.addEventListener('scroll', this.playVideosOnScorllForSmallScreens);
  },

  props : {
    media : Array
  },

  computed: {
    myMedia : function() {
      return this.media;
    },

    myLinks : function() {
      var linkz = []
      for (var i = 0; i < this.myMedia.length; i++) {
        const m = this.myMedia[i];
        var link = "";
        if (typeof m.mimeType !== "undefined") {
          if (m.mimeType.indexOf("image") >= 0) {
            link = { name: 'Image', params : { lang: this.$root.lang.shortName, imageId: m.id } };
          } else {
            link = { name: 'Video', params : { lang: this.$root.lang.shortName, videoId: m.id } };
          }
          linkz.push(link);
        }
      }
      return linkz;
    },

    myPosters: function() {
      var posterz = [];
      for (var i = 0; i < this.myMedia.length; i++) {
          const m = this.myMedia[i];
          if (typeof m.posterUrl !== "undefined") {
            var img = new Image();
            img.src = m.posterUrl;
            posterz.push(img);
          }
      }
      return posterz;
    },

    myGifs: function() {
      var gifz = [];
      for (var i = 0; i < this.myMedia.length; i++) {
          const m = this.myMedia[i];
          if (typeof m.posterUrl !== "undefined") {
            var img = new Image();
            img.src = m.gifUrl;
            if (m.mimeType.indexOf("image") >= 0) {
              img.src = m.posterUrl;
            }
          }
          gifz.push(img);
      }
      return gifz;
    }
  },

  methods : {
    playWebm: function(e) {
      // console.log(e.target.tagName);
      if (e.target.tagName === "VIDEO") {
        e.target.play();
      }
    },
    stopWebm: function(e) {
      // console.log(e.target);
      if (e.target.tagName === "VIDEO") {
        e.target.pause();
      }
    },
    playGif: function(e) {
      const index = e.target.getAttribute("rel");
      if (typeof this.myGifs[index] !== 'undefined') {
        e.target.style.backgroundImage = "url('" + this.myGifs[index].src + "')";
      }
    },
    stopGif: function(e) {
      const index = e.target.getAttribute("rel");
      if (typeof this.myPosters[index] !== 'undefined') {
        e.target.style.backgroundImage = "url('" + this.myPosters[index].src + "')";
      }
    }, 
    playVideosOnScorllForSmallScreens: function(e) {
      // console.log(window.innerWidth);
      if (window.innerWidth < 567) {
        const videoEl = document.querySelectorAll('.gridVideo');
        // console.log(videoEl);
        const diff = 175;
        const windowHeight = window.innerHeight;
        for (var i = 0; i < videoEl.length; i++) {
          //Vadim velicinu tekuceg videa
          const thisVideoEl = videoEl[i];
          const videoClientRect = thisVideoEl.getBoundingClientRect().top;
          const polaEkrana = windowHeight / 2;
          if ((videoClientRect < polaEkrana + diff) && (videoClientRect > polaEkrana - diff)) {
            //Ako jeste, pusti video
            thisVideoEl.play();
          } else {
            //Ako nije jos stigao ili je prosao, pauziraj video
            thisVideoEl.pause();
          }
        }
      }
    }
  }
}
</script>
<style></style>
